@import '../../../../styles/_variables.scss';



.list {
    margin: 30px 0;
    max-height: 35vh;
    overflow: scroll;
    .list_item {
        margin-bottom: 10px;
        padding: 5px 0;
        cursor: pointer;
        border-bottom: 1px solid $main_purple;
    }
}
