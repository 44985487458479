.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e5e5e5;

  .formContainer {
    width: 660px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 7px 64px 0px;
    padding: 20px 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.logoBox {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  padding: 10px 30px 10px 0px;
  margin-bottom: 10px;
}
