@import 'src/styles/_variables.scss';

.drawerArrow {
    position: absolute;
    padding: 10px;
    background-color: $main_orange;
    border-radius: 50%;
    right: -18px;
    transition: all 0.23s ease;
    cursor: pointer;
    top: 5px;
    display: flex;
    svg {
        width: 100%;
        height: 100%;
        color: #fff;
    }
    &.expand {
        transform: rotate(180deg);
    }
}
