@import "src/styles/_variables.scss";

.box {
  margin: 0 30px;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
}
.iconActive {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $main_orange;
  border-radius: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.icon:hover,
.icon:focus {
  border: 2px solid $main_orange;
  border-radius: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.popper {
  padding: 10px;
  background-color: $main_orange;
  .link {
    font-size: 16px;
    color: #fff;
    display: block;
    padding: 15px;
    cursor: pointer;
  }
}
.btnPopper {
  justify-content: center;
  font-size: 36px;
}
.rtl {
  direction: rtl;
}
