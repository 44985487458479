@import 'src/styles/_variables.scss';

.button {
    display: inline-flex;
    justify-content: center;
    font-size: 17px;
    padding: 10px 0;
    min-width: 140px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #fff;
    &.dismiss {
        color: #000;
        border: 1px solid black;
    }
    &.action {
        color: #ffffff;
        background-color: $main_orange;
    }
    &.actionBorder {
        color: $main_orange;
        background-color: #fff;
        border: 1px solid $main_orange;
        font-size: 14px;
    }
    &.disabled {
        color: #fff;
        background-color: #aaaaaa;
        cursor: default;
    }
}
