@import 'src/styles/_variables.scss';

.container {
    padding: 40px;
    width: 30%;
  }
  .form {
    margin-bottom: 30px;
  }
  .input:not(:last-of-type) {
    margin-bottom: 15px;
  }
  .option {
    padding: 10px 30px;
    &:first-of-type {
      border-bottom: 1px solid $main_orange;
    }
  }
  