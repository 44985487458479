@import 'src/styles/_variables.scss';

.container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  padding: 0px 40px;
}
.btn {
  background-color: #f0f0f0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 8px 15px;
  max-width: 70%;
  border-radius: 7px;
  color: #182026;
  &:hover,
  &:focus {
    background-color: $main_orange;
  }
}
