@import 'src/styles/_variables.scss';

.container {
    display: block;
    padding: 40px 60px;
    width: 100%;
}
.box {
    border-top: 1px solid #3e3e3e;
    border-left: 1px solid #3e3e3e;
    display: flex;
    width: 100%;
    height: 90%;
}
.column{
    border-right: 1px solid #3e3e3e;
}
.btn{
    display: block;
    padding: 20px 30px;
    color: #222a3f;
    &:hover,
    &:focus {
        color: $main_orange;
    }
}
.btnActive {
    color: $main_orange;
}
.boxRoute {
     padding: 15px 30px;
}