.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #e5e5e5;

  .formContainer {
    margin-top: 20px;
    width: 90vw;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 7px 64px 0px;
    padding: 40px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loginForm {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 40px;
  }
  .inputs {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  .btnLanguage {
    margin-left: auto;
    margin-right: 30px;
  }
  .registerForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    div,
    button {
      min-width: 300px;
      max-width: 300px;
    }

    div:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .logo {
    margin-bottom: 10px;
  }
  .send {
    cursor: pointer;
  }
  .placeholderAr {
    right: 0 !important;
    left: 85% !important;
  }
}
