@import 'src/styles/_variables.scss';

.modal {
  position: absolute;
  width: 300px;
  border: 2px solid $main_orange;
  padding: 30px;
  text-align: center;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (min-width: 768px) {
    width: 600px;
  }
  @media screen and (min-width: 1024px) {
    width: 900px;
  }
}
.title {
  text-transform: capitalize;
}
.pdf {
  position: relative;
  margin-top: -20px;
  height: 335px;
}
.notFound{
  margin-top: 30px;
  font-weight: 900;
}
.btns {
  display: flex;
}
.btn {
  margin: 10px;
}
