.drawer {
    display: inline-block;
    padding-top: 50px;
    background-color: rgb(122, 63, 111);
    box-shadow: rgba(122, 63, 111, 0.1) 4px 0px 4px 0px;
    min-height: calc(100vh - 60px);
    position: relative;
    z-index: 2;
    transition: all 0.23s ease;
    min-width: 40px;
    max-width: 40px;
    &.expand {
        min-width: 240px;
        max-width: 240px;
    }
}
