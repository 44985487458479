@font-face {
  font-family: "Kufyan Arabic";
  src:  local('Kufyan Arabic Regular'),url("../assets/fonts/KufyanArabicRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kufyan Arabic", sans-serif;
  src: local('Kufyan Arabic Bold'), url("../assets/fonts/KufyanArabicBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Farro";
  src:  local('Farro Regular'),url("../assets/fonts/FarroRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Farro", sans-serif;
  src: local('Farro Bold'), url("../assets/fonts/FarroBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}