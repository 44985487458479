.subNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cfd9d6;
  margin-bottom: 25px;
  padding: 8px;
}
.btnBack {
  border: 2px solid #fff;
}
.btnQR {
  padding: 5px 8px;
  color: black;
}
.title {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.titleTransparet {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  color: transparent;
}
.arStyle {
  flex-direction: row-reverse;
  button {
    span {
      flex-direction: row-reverse;
      margin: 0 5px;
    }
  }
}
