@import 'src/styles/_variables.scss';

.navigationItem {
    display: block;
    padding-left: 0;
    text-decoration: none;

    &.active {
        background-color: #fff;
        .navigationItem_container {
            background-color: $main_orange;
        }
    }
    .navigationItem_container {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 15px 0 15px 40px;
        padding-left: 0;
        .navigationItem_icon {
            height: 20px;
            width: 20px;
            margin: 0 auto;
            text-align: center;
            svg {
                width: 100%;
                height: 100%;
                fill: #fff;
            }
        }
        .navigationItem_title {
            display: block;
            color: #fff;
            font-size: 16px;
        }
    }
    &.expanded {
        padding-left: 5px;
        .navigationItem_container {
            padding: 15px 0 15px 40px;
            justify-content: flex-start;
        }
        .navigationItem_icon {
            margin-right: 10px;
            margin-left: 0;
        }
    }
}
