@import 'src/styles/_variables.scss';

.list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    & li {
        margin: 5px;
    }
}
.link {
    color: #000;
    padding: 5px 10px;
    border: 1px solid transparent;
    border-radius: 6px;
    font-size: 12px;
    &:hover {
        color:$main_orange;
        border: 1px solid $main_orange;
        border-radius: 6px;
    }
}
.activeLink{
    border: 1px solid #631f9d;
    border-radius: 6px;
    color: #631f9d;
}