.reactTable {
    border: none !important;
}

.reactTable [class='rt-thead -header'] {
    box-shadow: none !important;
    background-color: #f0f0f0;
    font-size: 16px;
    color: #3e3e3e;
}

.reactTable [role='columnheader'],
.reactTable [role='gridcell'] {
    display: flex;
    align-items: center;
    border-right: none !important;
    height: 40px;
}

.reactTable [role='columnheader']:last-child {
    justify-content: flex-end;
    padding-right: 20px;
}

.reactTable [role='gridcell']:last-child {
    justify-content: flex-end;
    padding-right: 50px;
}

.reactTable [class='rt-resizable-header-content'] {
    text-align: left;
}
