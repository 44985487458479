.userLogo {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 20px !important;
    overflow: hidden !important;
}
.userMenu {
    margin-left: 30px;
}
.headerClient {
    display: flex;
    justify-content: space-between;
}