*,
body {
  margin: 0;
  padding: 0;
}

p {
  font-size: 10px;
  margin: 10px;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}