.stringTable {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.columnDate {
  border-right: 1px solid #f0f0d0;
}
.columnAction {
  padding-left: 10px;
}
.boxLog {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0 5px;
    padding: 0;
    margin-left: 10px;
  }
}
.arColumnDate{
  border-left: 1px solid #f0f0d0;
  border-right: none;
  text-align: right;
}
.arColumnAction{
   padding-left: 0;
    padding-right: 10px;
    text-align: right;
}