.title {
  color: #3e3e3e;
  background-color: #f0f0f0;
  padding: 12px 30px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
}
.logsTable {
  width: 100%;
  padding: 10px 20px;
  text-align: left;
}
.stringTable {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.columnDate {
  border-right: 1px solid #f0f0d0;
  width: 20%;
  p {
    font-size: 14px;
  }
}
.columnAction {
  padding-left: 30px;
}
.btn {
  width: 50%;
}
.boxLog {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logText {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
