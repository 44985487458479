@import 'src/styles/_variables.scss';

.headerBlock {
  display: flex;
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: center;
    color: #222a3f;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.5;
   margin-right: auto;
  }

  .routeBtn {
    margin-left: auto;
    cursor: pointer;
    background: none $main_orange;
    padding: 10px 25px;
    font-size: 17px;
    color: #ffffff;
    border-radius: 50px;
    text-decoration: none;
  }

  .routeBtn:hover,
  .routeBtn:focus {
    color: #000;
    background-color: #f0f0f0;
  }
}
