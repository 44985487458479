.styleAr {
    a, button {
        font-family: 'Kufyan Arabic', serif;
    }
}
.styleEng {
    a, button {
        font-family: 'Farro', sans-serif;
    }
}
