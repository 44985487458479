.link {
  margin-bottom: 10px;
  width: 250px;
  font-size: 16px;
  color: black;
  word-wrap: break-word; 
  text-decoration: underline;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.btns {
  display: flex;
}
.btn {
  margin: 10px;
}
.box {
  margin-bottom: 20px;
}